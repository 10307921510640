import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | "Смак України: Майстерність Традиційної Кухні"
			</title>
			<meta name={"description"} content={"Відповідаємо на самі розповсюдженні питання про кулінарну подорож в світ Української кухні."} />
			<meta property={"og:title"} content={"FAQ | \"Смак України: Майстерність Традиційної Кухні\""} />
			<meta property={"og:description"} content={"Відповідаємо на самі розповсюдженні питання про кулінарну подорож в світ Української кухні."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				FAQ{"\n\n"}
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Відповідаємо на самі розповсюдженні питання про кулінарну подорож в світ Української кухні.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи потрібен мені попередній кулінарний досвід для участі в цих курсах?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ні, ніякого попереднього кулінарного досвіду не потрібно. Наші курси відкриті для всіх, навіть для початківців. Ми надаємо докладні інструкції та практичні вправи, щоб кожен міг опанувати мистецтво готування українських страв.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Як часто проводяться ці курси?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Розклад курсів може змінюватися, але ми намагаємося проводити їх регулярно, зазвичай один або декілька разів на тиждень. Для отримання актуальної інформації про дати та графік курсів, звертайтеся до нашого відділу обслуговування клієнтів.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Як можна зареєструватися на курси?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Для реєстрації на курси, зазвичай, необхідно заповнити онлайн-форму на нашому веб-сайті або зв'язатися з нашим відділом обслуговування клієнтів. Ми також надаємо інформацію про оплату та доступні методи реєстрації на нашому сайті.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Які вартість інвестиції в ці курси?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Вартість курсів може варіюватися в залежності від тривалості та обсягу навчального матеріалу. Для отримання точної інформації про вартість, будь ласка, звертайтеся до нашого відділу обслуговування клієнтів.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Чи отримую учасники сертифікат після закінчення курсів?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Так, всі учасники, які успішно завершать наші курси, отримають офіційний сертифікат, що підтверджує їхні навички та участь у навчальному процесі.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Які матеріали та обладнання потрібно мати з собою на курси?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Ми надаємо всі необхідні продукти та обладнання для проведення курсів. Вам може знадобитися лише зручний кухонний фартук та головний убір, якщо ви бажаєте.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0px 0 30px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Якщо у вас є додаткові питання, будь ласка, не соромтеся зв'язатися з нами. Ми завжди готові надати вам докладну інформацію та відповісти на всі ваші запитання.{"\n\n"}
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				href="/contacts"
				text-decoration-line="initial"
				background="--color-secondary"
				color="--dark"
			>
				Написати
			</Button>
		</Section>
		<Section padding="0px 0 48px 0" md-padding="36px 0 36px 0" quarkly-title="Images-19">
			<Override slot="SectionContent" width="100%" max-width="100%" />
			<Image src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15:58:17.355Z" object-fit="cover" srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/close-up-senior-couple-preparing-food-kitchen_23-2147901290.jpg?v=2023-10-06T15%3A58%3A17.355Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});